<!-- <div class="mt-3" style="text-align: center">
  <h1>Gold Stars</h1>
</div>
<div class="row" style="margin-top: -10px">
  <div style="margin-left: 59px" class="col-md-6">
    <nav>
      <a routerLink="/two-time-table">Two Time Table</a>&nbsp;
      <a routerLink="/three-time-table">Three Time Table</a>&nbsp;
      <a routerLink="/correct-ball">Correct Ball</a>
    </nav>
  </div>
</div>

<hr style="opacity: 0" /> -->

<router-outlet style="margin-top: 30px"></router-outlet>
