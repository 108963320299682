<div class="row content-area">
  <div class="row title-area">
    <div class="col-md-3"></div>
    <div class="mt-3 mb-3 col-md-6">
      <div class="row">
        <h6 style="font-size: 30px" class="text-center">Two Times Table</h6>
      </div>
    </div>
    <div class="col-md-3"></div>
  </div>

  <div class="row task-area">
    <div class="card">
      <p style="text-align: center; margin-top: 10px; font-size: 25px">
        Write the answers. Circle the ones you know by heart.
      </p>
      <div class="row mt-1 form-group">
        <div
          *ngFor="let table of tables"
          [attr.data-value1]="table.value1"
          [attr.data-value2]="table.value2"
          class="col-md-3 mt-3"
        >
          <label
            ><span class="multiple">{{ table.value1 }} x </span
            ><span class="table">{{ table.value2 }}</span>
            <span style="color: rgb(79, 190, 235)" class="multiple">
              =</span
            ></label
          >&nbsp;
          <input
            id="{{ table.id }}"
            [attr.data-id]="table.id"
            [attr.data-value1]="table.value1"
            [attr.data-value2]="table.value2"
            type="text"
            maxlength="3"
            class="form-control-sm"
            (keyup)="onKey($event)"
          />
        </div>
      </div>
      <div class="row mt-5 form-group">
        <div
          *ngFor="let table of tables1"
          [attr.data-value1]="table.value1"
          [attr.data-value2]="table.value2"
          class="col-md-3 mt-3"
        >
          <label
            ><span class="table">{{ table.value1 }}</span
            ><span class="multiple"> x {{ table.value2 }} =</span></label
          >&nbsp;
          <input
            id="{{ table.id }}"
            [attr.data-id]="table.id"
            [attr.data-value1]="table.value1"
            [attr.data-value2]="table.value2"
            type="text"
            maxlength="3"
            class="form-control-sm"
            (keyup)="onKey($event)"
          />
        </div>
      </div>

      <div class="row mt-5">
        <div class="col-md-6">
          <p style="font-size: 20px; margin-left: 30px">
            Write the missing numbers.
          </p>
        </div>
      </div>

      <div class="mt-3"></div>

      <div class="row mt-3">
        <div class="col-md-1 image">
          <img
            src="assets/images/SimpleStar.png"
            id="image1"
            style="margin-left: 10px; transform: rotate(60deg)"
            width="70px"
            height="70px"
          />
          <div style="margin-left: 1px" class="centered">2</div>
        </div>

        <div style="margin-top: -10px" class="col-md-1 image">
          <img
            src="assets/images/SimpleStar.png"
            style="margin-left: 20px; transform: rotate(-60deg)"
            width="70px"
            height="70px"
          />
          <div style="margin-left: 15px; margin-top: -5px" class="centered">
            4
          </div>
        </div>

        <div style="margin-top: -10px" class="col-md-1 image">
          <img
            src="assets/images/SimpleStar.png"
            style="margin-left: 30px; transform: rotate(40deg)"
            width="70px"
            height="70px"
          />
          <div style="margin-left: 22px; margin-top: -3px" class="centered">
            6
          </div>
        </div>

        <div style="margin-top: -10px" class="col-md-1 image">
          <img
            src="assets/images/SimpleStar.png"
            style="margin-left: 40px; transform: rotate(70deg)"
            width="70px"
            height="70px"
          />
        </div>

        <div style="margin-top: -10px" class="col-md-1 image">
          <img
            src="assets/images/SimpleStar.png"
            style="margin-left: 50px; transform: rotate(-30deg)"
            width="70px"
            height="70px"
          />
        </div>

        <div style="margin-top: -20px" class="col-md-1 image">
          <img
            src="assets/images/SimpleStar.png"
            style="margin-left: 60px; transform: rotate(-10deg)"
            width="70px"
            height="70px"
          />
          <div style="margin-left: 50px; margin-top: -5px" class="centered">
            12
          </div>
        </div>

        <div class="col-md-1 image">
          <img
            src="assets/images/SimpleStar.png"
            style="margin-left: 70px; transform: rotate(30deg)"
            width="70px"
            height="70px"
          />
        </div>

        <div style="margin-top: -20px" class="col-md-1 image">
          <img
            src="assets/images/SimpleStar.png"
            style="margin-left: 80px; transform: rotate(50deg)"
            width="70px"
            height="70px"
          />
          <div style="margin-left: 70px; margin-top: -5px" class="centered">
            16
          </div>
        </div>

        <div style="margin-top: -10px" class="col-md-1 image">
          <img
            src="assets/images/SimpleStar.png"
            style="margin-left: 90px; transform: rotate(50deg)"
            width="70px"
            height="70px"
          />
        </div>

        <div style="margin-top: -10px" class="col-md-1 image">
          <img
            src="assets/images/SimpleStar.png"
            style="margin-left: 100px; transform: rotate(-20deg)"
            width="70px"
            height="70px"
          />
        </div>
      </div>
    </div>
  </div>

  <div class="row footer-area">
    <div class="task-footer">
      <p style="margin-top: 1px; margin-left: 260px; color: black">
        Note for parent: Remind your child that multiplication is repeated
        addition.
        <br />
        <span
          >for example: 2 x 2 = 2 + 2. Practise reciting the two times table.
        </span>
      </p>
    </div>

    <div style="margin-top: -190px; margin-left: 90px; position: relative">
      <img
        src="assets/images/star.png"
        height="180px"
        width="190px"
        class="image"
        alt="star"
      />
    </div>
  </div>
</div>
