import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-three-time-table',
  templateUrl: './three-time-table.component.html',
  styleUrls: ['./three-time-table.component.scss'],
})
export class ThreeTimeTableComponent implements OnInit {
  constructor() {}

  tables = [
    { id: 'result_1', value1: 1, value2: 3 },
    { id: 'result_2', value1: 2, value2: 3 },
    { id: 'result_3', value1: 3, value2: 3 },
    { id: 'result_4', value1: 4, value2: 3 },
    { id: 'result_5', value1: 5, value2: 3 },
    { id: 'result_6', value1: 6, value2: 3 },
    { id: 'result_7', value1: 7, value2: 3 },
    { id: 'result_8', value1: 8, value2: 3 },
    { id: 'result_9', value1: 9, value2: 3 },
    { id: 'result_10', value1: 10, value2: 3 },
  ];

  tables1 = [
    { id: 'tables_1', value1: 3, value2: 1 },
    { id: 'tables_2', value1: 3, value2: 2 },
    { id: 'tables_3', value1: 3, value2: 3 },
    { id: 'tables_4', value1: 3, value2: 4 },
    { id: 'tables_5', value1: 3, value2: 5 },
    { id: 'tables_6', value1: 3, value2: 6 },
    { id: 'tables_7', value1: 3, value2: 7 },
    { id: 'tables_8', value1: 3, value2: 8 },
    { id: 'tables_9', value1: 3, value2: 9 },
    { id: 'tables_10', value1: 3, value2: 10 },
  ];

  ngOnInit() {}

  onKey(event: any) {
    var data = event.target.dataset.value1 * event.target.dataset.value2;
    var inputValue = event.target.value;
    if (data == inputValue) {
      document.getElementById(event.target.dataset.id).style.backgroundColor =
        'green';
    } else if (data != inputValue) {
      document.getElementById(event.target.dataset.id).style.backgroundColor =
        'red';
    }

    document.getElementById(event.target.dataset.id).style.color = 
    'white';
  }
}
