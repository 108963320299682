<div class="row content-area">
  <div class="row title-area">
    <div class="col-md-3"></div>
    <div class="mt-3 mb-3 col-md-6">
      <div class="row">
        <h6 style="font-size: 30px" class="text-center">Three Times Table</h6>
      </div>
    </div>
    <div class="col-md-3"></div>
  </div>

  <div class="row task-area">
    <div class="card">
      <p style="text-align: center; margin-top: 10px; font-size: 25px">
        Write the answers. Circle the ones you know by heart.
      </p>
      <div class="row mt-1 form-group">
        <div class="col-md-6">
          <div
            *ngFor="let table of tables"
            [attr.data-value1]="table.value1"
            [attr.data-value2]="table.value2"
            class="col-auto mt-2"
            style="margin-left: 100px"
          >
            <label
              ><span class="multiple">{{ table.value1 }} x </span
              ><span class="table">{{ table.value2 }}</span>
              <span style="color: rgb(9, 148, 9)" class="multiple">
                =
              </span></label
            >&nbsp;
            <input
              id="{{ table.id }}"
              [attr.data-id]="table.id"
              [attr.data-value1]="table.value1"
              [attr.data-value2]="table.value2"
              type="text"
              maxlength="3"
              class="form-control-sm"
              (keyup)="onKey($event)"
            />
          </div>
        </div>
        <div class="col-md-6">
          <div
            *ngFor="let table of tables1"
            [attr.data-value1]="table.value1"
            [attr.data-value2]="table.value2"
            class="col-auto mt-2"
          >
            <label
              ><span class="table">{{ table.value1 }}</span
              ><span class="multiple"> x {{ table.value2 }} = </span></label
            >&nbsp;
            <input
              id="{{ table.id }}"
              [attr.data-id]="table.id"
              [attr.data-value1]="table.value1"
              [attr.data-value2]="table.value2"
              type="text"
              maxlength="3"
              class="form-control-sm"
              (keyup)="onKey($event)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row footer-area">
    <div class="task-footer">
      <p style="margin-top: 1px; margin-left: 250px; color: black">
        Note for parent: Remind your child that multiplication is repeated
        addition.
        <br />
        <span>
          For example: 2 x 3 = 3 + 3. Practise reciting the three times table.
        </span>
      </p>
    </div>

    <div style="margin-top: -180px; margin-left: 90px; position: relative">
      <img
        src="assets/images/yellow.png"
        height="170px"
        width="170px"
        class="image"
        alt="star"
      />
    </div>
  </div>
</div>
