import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-correct-balls',
  templateUrl: './correct-balls.component.html',
  styleUrls: ['./correct-balls.component.scss'],
})
export class CorrectBallsComponent implements OnInit {
  twelve: boolean = false;
  forty: boolean = false;
  gaps: boolean = true;
  sixteen: boolean = false;
  twentyFour: boolean = false;
  circleValue: any;

  rectangles = [
    {
      id: 'result_1',
      rectangle1: 3,
      rectangle2: 4,
    },
    {
      id: 'result_2',
      rectangle1: 10,
      rectangle2: 4,
    },
    {
      id: 'result_3',
      rectangle1: 4,
      rectangle2: 4,
    },
    {
      id: 'result_4',
      rectangle1: 6,
      rectangle2: 4,
    },
  ];

  circles = [
    {
      id: 'circle_1',
      circle: 8,
    },
    {
      id: 'circle_2',
      circle: 24,
    },
    {
      id: 'circle_3',
      circle: 12,
    },
    {
      id: 'circle_4',
      circle: 28,
    },
    {
      id: 'circle_5',
      circle: 40,
    },
    {
      id: 'circle_6',
      circle: 16,
    },
  ];

  constructor() {}

  ngOnInit() {
    setTimeout(() => {
      document.getElementById('circle_1').style.backgroundColor = 'aqua';
      document.getElementById('circle_2').style.backgroundColor = 'salmon';
      document.getElementById('circle_3').style.backgroundColor =
        'rgb(189, 132, 189)';
      document.getElementById('circle_4').style.backgroundColor =
        'rgb(248, 233, 16)';
      document.getElementById('circle_5').style.backgroundColor =
        'rgb(233, 105, 233)';
      document.getElementById('circle_6').style.backgroundColor = 'skyblue';
      document.getElementById('result_1').style.backgroundColor = 'whitesmoke';
      document.getElementById('result_3').style.backgroundColor =
        'rgb(238, 238, 143)';
      document.getElementById('result_4').style.backgroundColor = 'sandybrown';
    }, 100);
  }

  onRectangle(event: any) {
    var data =
      event.target.dataset.rectangle1 * event.target.dataset.rectangle2;
    this.circleValue = data;
    if (event.target.id == event.target.dataset.id) {
      document.getElementById(event.target.dataset.id).style.boxShadow =
        '5px 10px blue';
    }
  }

  onCircle(event: any) {
    if (
      this.circleValue == 12 &&
      this.circleValue == event.target.dataset.circle
    ) {
      this.gaps = false;
      this.twelve = true;
      this.forty = false;
      this.sixteen = false;
      this.twentyFour = false;
    }
    if (
      this.circleValue == 40 &&
      this.circleValue == event.target.dataset.circle
    ) {
      this.gaps = false;
      this.forty = true;
      this.twelve = false;
      this.sixteen = false;
      this.twentyFour = false;
    }
    if (
      this.circleValue == 16 &&
      this.circleValue == event.target.dataset.circle
    ) {
      this.gaps = false;
      this.forty = false;
      this.twelve = false;
      this.sixteen = true;
      this.twentyFour = false;
    }
    if (
      this.circleValue == 24 &&
      this.circleValue == event.target.dataset.circle
    ) {
      this.gaps = false;
      this.forty = false;
      this.twelve = false;
      this.sixteen = false;
      this.twentyFour = true;
    }
  }
}
