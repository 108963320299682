<div class="mt-3"></div>

<div class="row mt-5">
  <div
    *ngFor="let rectangle of rectangles"
    style="padding-left: 70px"
    class="col-md-3"
  >
    <input
      type="button"
      id="{{ rectangle.id }}"
      [attr.data-id]="rectangle.id"
      [attr.data-rectangle1]="rectangle.rectangle1"
      [attr.data-rectangle2]="rectangle.rectangle2"
      value="{{ rectangle.rectangle1 }}x{{ rectangle.rectangle2 }}"
      style="width: 200px; height: 100px; border-color: rgb(2, 1, 7)"
      (click)="onRectangle($event)"
    />
  </div>
</div>

<div *ngIf="gaps" class="circles" style="margin-top: 190px"></div>

<svg *ngIf="twelve" width="900" height="190">
  <line x1="-20" y1="-140" x2="580" y2="230" stroke="purple" />
</svg>

<svg *ngIf="forty" style="margin-left: 400px" width="900" height="190">
  <line x1="-20" y1="-100" x2="580" y2="200" stroke="purple" />
</svg>

<svg *ngIf="sixteen" style="margin-left: 650px" width="900" height="190">
  <line x1="-20" y1="-100" x2="580" y2="200" stroke="skyblue" />
</svg>

<svg *ngIf="twentyFour" style="margin-left: 200px" width="900" height="190">
  <line x1="100" y1="190" x2="1000" y2="-30" stroke="salmon" />
</svg>

<div style="margin-top: -28px" class="row">
  <div
    *ngFor="let circle of circles"
    style="padding-left: 70px"
    class="col-md-2 mt-3"
  >
    <input
      type="button"
      id="{{ circle.id }}"
      value="{{ circle.circle }}"
      [attr.data-id]="circle.id"
      [attr.data-circle]="circle.circle"
      style="
        width: 70px;
        height: 70px;
        border-radius: 100px;
        border-color: rgb(2, 1, 7);
      "
      (click)="onCircle($event)"
    />
  </div>
</div>
