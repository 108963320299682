import { AppComponent } from './app.component';
import { CorrectBallsComponent } from './correct-balls/correct-balls.component';
import { TwoTimeTableComponent } from './two-time-table/two-time-table.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ThreeTimeTableComponent } from './three-time-table/three-time-table.component';

const routes: Routes = [
  {
    path: '',
    component: AppComponent,
    children: [
      {
        path: '',
        redirectTo: 'two-time-table',
        pathMatch: 'full',
      },
      {
        path: 'two-time-table',
        component: TwoTimeTableComponent,
      },
      {
        path: 'three-time-table',
        component: ThreeTimeTableComponent,
      },
      {
        path: 'correct-ball',
        component: CorrectBallsComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
export const routingComponents = [TwoTimeTableComponent, CorrectBallsComponent];
